.color-button-palette__wrapper {
    margin-right: 50px;
}

.color-button-palette__button {
    display: block;
    width: 80px;
    height: 40px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    transition: transform 100ms;
    cursor: pointer;
}

.color-button-palette__button:first-child {
    border-top: 1px solid #000;
}

.color-button-palette__button:hover {
    transform: scale(1.1);
    border-top: 1px solid #000;
}

.color-button-palette__button:active {
    transform: scale(1.05);
}