.footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 75%;
}

.footer__icon-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer__icon {
    width: 50px;
    height: 50px;
    margin: 0 10px;
    cursor: pointer;
    transition: transform 100ms;
}

.footer__icon:hover {
    transform: scale(1.1);
}

.footer__icon:active {
    transform: scale(1.05);
}