.stripe-button {
    display: block;
    height: 100px;
    width: 25px;
    background: transparent;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    border-left: 1px solid gray;
    border-right: 1px solid transparent;
    cursor: pointer;
    transition: transform 100ms;
}

.stripe-button:last-child {
    border-right: 1px solid gray;
}

.stripe-button:hover {
    border-right: 1px solid gray;
    transform: scale(1.1);
}

.stripe-button:active {
    transform: scale(1.05);
}

.stripe-button--active {
    transform: scale(1.05);
    border: 1px solid #000 !important;
}